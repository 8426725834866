import axios from 'axios'
import config from './config'

const BE_API = axios.create({
  baseURL: config.API_URL,
  headers: {
    api_key: 'A3861F11-254F-4A79-A803-FA7A3CCE82C7',
  },
})

const INTERNAL_IP_API = axios.create({
  baseURL: config.INTERNAL_IP_API_URL,
})

const MAGICINFO_TRANSCODE_API = axios.create({
  baseURL: config.MAGICINFO_TRANSCODE_API_URL,
  headers: {
    'X-Gateway-APIKey': config.MAGICINFO_TRANSCODE_API_KEY,
  },
})

export default {
  internalIp: {
    get: () => INTERNAL_IP_API.get('/'),
  },
  display: {
    getConfig: displayId =>
      BE_API.get('/displays/getConfiguration', { params: { displayId } }),
    getId: ip =>
      MAGICINFO_TRANSCODE_API.get(config.MAGICINFO_TRANSCODE_API_ENDPOINT, { params: { ip } }),
  },
}
